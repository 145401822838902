import React, { useState, useEffect } from 'react';

const styles = {
  teaserContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '32px',
    padding: '32px',
  },
  teaserBlock: {
    cursor: 'pointer',
    border: 'none',
    borderRadius: '16px',
    overflow: 'hidden',
    transition: 'all 0.3s ease',
    backgroundColor: 'white',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',  // Initial shadow
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 15px 30px rgba(0, 0, 0, 0.15)' // Bigger shadow on hover
    }
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    height: '220px',
    overflow: 'hidden'
  },
  teaserImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'transform 0.5s ease'  // Smooth transition for zoom
  },
  teaserContent: {
    padding: '24px',
    borderTop: '1px solid #f0f0f0'
  },
  publication: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#6941C6',
    marginBottom: '8px',
    textTransform: 'uppercase',
    letterSpacing: '0.5px'
  },
  teaserTitle: {
    margin: '0 0 12px 0',
    fontSize: '20px',
    fontWeight: '600',
    color: '#1A1A1A',
    lineHeight: '1.4',
    letterSpacing: '-0.01em'
  },
  teaserDate: {
    fontSize: '14px',
    color: '#667085',
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  dateDot: {
    width: '4px',
    height: '4px',
    borderRadius: '50%',
    backgroundColor: '#D0D5DD'
  },
  modal: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    padding: '24px',
    backdropFilter: 'blur(4px)'
  },
  modalContent: {
    backgroundColor: 'white',
    borderRadius: '24px',
    width: '100%',
    maxWidth: '900px',
    maxHeight: '90vh',
    overflow: 'auto',
    position: 'relative',
    padding: '32px',
    boxShadow: '0 24px 48px rgba(0, 0, 0, 0.2)'
  },
  modalImage: {
    width: '100%',
    height: 'auto',
    maxHeight: '500px',
    objectFit: 'cover',
    borderRadius: '16px',
    marginBottom: '32px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
  },
  modalHeader: {
    marginBottom: '24px',
  },
  modalPublication: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#6941C6',
    marginBottom: '12px',
    textTransform: 'uppercase',
    letterSpacing: '0.5px'
  },
  modalTitle: {
    fontSize: '32px',
    fontWeight: '700',
    color: '#1A1A1A',
    lineHeight: '1.3',
    marginBottom: '16px',
    letterSpacing: '-0.02em'
  },
  modalDate: {
    fontSize: '16px',
    color: '#667085',
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  modalText: {
    fontSize: '18px',
    lineHeight: '1.7',
    color: '#374151',
    '& p': {
      marginBottom: '24px'
    }
  },
  closeButton: {
    position: 'absolute',
    top: '24px',
    right: '24px',
    width: '40px',
    height: '40px',
    border: 'none',
    borderRadius: '50%',
    backgroundColor: 'white',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '24px',
    color: '#1A1A1A',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#f8f9fa',
      transform: 'scale(1.1)'
    }
  }
};

const Newspaper = () => {
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [articles, setArticles] = useState([]);
    const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    fetch('press-articles.json')
      .then(response => response.json())
      .then(data => setArticles(data))
      .catch(error => console.error('Error loading articles:', error));
  }, []);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('hu-HU', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <div style={styles.teaserContainer}>
      {articles.map((article, index) => (
        <div
          key={index}
          style={{
            ...styles.teaserBlock,
            transform: hoveredIndex === index ? 'translateY(-5px)' : 'none',
            boxShadow: hoveredIndex === index 
              ? '0 15px 30px rgba(0, 0, 0, 0.15)'
              : '0 4px 10px rgba(0, 0, 0, 0.1)'
          }}
          onClick={() => setSelectedArticle(article)}
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <div style={styles.imageContainer}>
            <img
              src={article.screenshotPath}
              alt={article.title}
              style={{
                ...styles.teaserImage,
                transform: hoveredIndex === index ? 'scale(1.1)' : 'scale(1)'
              }}
            />
          </div>
          <div style={styles.teaserContent}>
            <div style={styles.publication}>{article.publication}</div>
            <h3 style={styles.teaserTitle}>{article.title}</h3>
            <div style={styles.teaserDate}>
              <span>{formatDate(article.date)}</span>
            </div>
          </div>
        </div>
      ))}

      {/* Modal remains the same */}
      {selectedArticle && (
        <div style={styles.modal} onClick={() => setSelectedArticle(null)}>
          <div style={styles.modalContent} onClick={e => e.stopPropagation()}>
            <button 
              style={styles.closeButton}
              onClick={() => setSelectedArticle(null)}
            >
              ×
            </button>
            <img
              src={selectedArticle.screenshotPath}
              alt={selectedArticle.title}
              style={styles.modalImage}
            />
            <div style={styles.modalHeader}>
              <div style={styles.modalPublication}>{selectedArticle.publication}</div>
              <h2 style={styles.modalTitle}>{selectedArticle.title}</h2>
              <div style={styles.modalDate}>
                {formatDate(selectedArticle.date)}
              </div>
            </div>
            <div style={styles.modalText}>
              {selectedArticle.textContent.split('\n').map((paragraph, i) => (
                paragraph.trim() && <p key={i}>{paragraph}</p>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Newspaper;