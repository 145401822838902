import React, { useState, useEffect, useRef } from 'react';
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from '../firebase';
import Typed from 'typed.js';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/style.css';
import '../styles/pictures.css';
import '../styles/contact.css';
import '../styles/button.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Ensure Bootstrap JS is loaded

import elerheto1 from "../feltoltesek/kep13.jpg";
import elerheto2 from "../feltoltesek/kep12.jpg";
import elerheto3 from "../feltoltesek/kep11.jpg";

import bgImage from '../paint-brush-aboutme.svg'; 
import Contact from './Contact';

import matartBlack from "../logo-images/matart-logo.png";
import matartWhite from "../logo-images/matart-logo-white.png";

import moreBlack from "../logo-images/more.png";
import moreWhite from "../logo-images/more-white.png";
import Newspaper from './Newspaper';

const Gallery = () => {
  const [images, setImages] = useState([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupImageSrc, setPopupImageSrc] = useState('');
  const [scrolled, setScrolled] = useState(false);
  const typedRef = useRef(null);

  useEffect(() => {
    const fetchImages = async () => {
      const storageRef = ref(storage, 'uploads/');
      try {
        const result = await listAll(storageRef);
        const imageUrls = await Promise.all(
          result.items.map((itemRef) => getDownloadURL(itemRef))
        );
        setImages(imageUrls);
      } catch (error) {
        console.error("Error fetching images from Firebase Storage:", error);
      }
    };

    fetchImages();

    // Initialize Typed.js
    const typed = new Typed(typedRef.current, {
      strings: ["Kreatív", "Egyedi", "Művészi"],
      typeSpeed: 100,
      backSpeed: 100,
      loop: true,
    });

    return () => {
      // Destroy Typed instance during cleanup to prevent memory leaks
      typed.destroy();
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const popUp = (src) => {
    setPopupImageSrc(src);
    setIsPopupVisible(true);
    document.body.style.overflow = 'hidden'; // Disable scrolling
  };

  const popUpDisable = () => {
    setIsPopupVisible(false);
    document.body.style.overflow = 'auto'; // Enable scrolling
  };

  const settings = {
    dots: true, // Enable dots
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: true,
  };
  
  useEffect(() => {
    const scrollFunction = () => {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        document.getElementById("myBtn").style.display = "block";
      } else {
        document.getElementById("myBtn").style.display = "none";
      }
    };

    window.onscroll = function () {
      scrollFunction();
    };

    return () => {
      window.onscroll = null;
    };
  }, []);

  const topFunction = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div id="body-id">
      <div className={`navbar-holder ${scrolled ? 'scrolled' : ''}`} id="navbar-bg">
        <nav className="navbar navbar-expand-lg container-lg bg-none">
          <div className="container-fluid">
            <img src={scrolled ? matartWhite : matartBlack} alt="" className="logo" id="nav-logo" />
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <img src={scrolled ? moreWhite : moreBlack} className="more-img" alt="" id="nav-more" />
            </button>
            <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link" id="nav-button1" href="#munkaim">
                    Aktuális
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="nav-button2" href="#galeria">
                    Galéria
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="nav-button3" href="#rolam">
                    Rólam
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="nav-button5" href="#media">
                    Média
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="nav-button4" href="#elerhetoseg">
                    Elérhetőség
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>

      {/* Full-screen popup */}
      {isPopupVisible && (
        <div 
          className="popup-overlay" 
          onClick={popUpDisable} 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000
          }}>
          <img src={popupImageSrc} alt="Popup" style={{ maxHeight: '90%', maxWidth: '90%' }} />
        </div>
      )}

      <img src="images/csordogalo.png" alt="" className="csordogal" />

      <button
        onClick={topFunction}
        id="myBtn"
        title="Go to top"
        style={{ display: 'none' }}
      >
        <img src="images/up.png" alt="" style={{ width: '35px', height: 'auto' }} />
      </button>

      <div className="container gallery-container" style={{ marginTop: '10vh' }}>

        <div className="slideshow-container text-center">
          <p id="welcome-text">
            <span ref={typedRef} className="auto-input"></span> alkotások
          </p>
          <Slider {...settings}>
            <div>
              <img src="images/festmenyek/festmeny3.JPG" className="slider-img" alt="Festmeny 3" />
            </div>
            <div>
              <img src="images/festmenyek/festmeny4.JPG" className="slider-img" alt="Festmeny 4" />
            </div>
            <div>
              <img src="images/festmenyek/festmeny5.JPG" className="slider-img" alt="Festmeny 5" />
            </div>
          </Slider>
        </div>
      </div>

      <div id="munkaim" className="gallery-container">
        <h1 style={{ fontWeight: "bold", marginTop: 48, marginBottom: 48 }}>Elérhető munkáim</h1>
        <p className="welcome-long">
          <b>Mátyás Tamás</b> vagyok! <br /> <br />
          Üdvözöllek a <b>kevert műfajú</b> festményeim <i>"világában"!</i> <br />
          Itt megtalálod az általam készített egyedi festményeket, amelyeket eladásra szántam. 
          Minden alkotásomat szeretettel és gondosan készítem. <br /> <br />
          Minden festményem eladó, és örömmel segítek neked a választásban, az árban vagy bármilyen egyéb kérdésben. 
          Ha érdeklődnél valamelyik művem iránt, vagy szeretnél rendelést leadni, kérlek, vedd fel velem a{' '}
          <a href="#elerhetoseg" className="welcomelong-contact">kapcsolatot</a>!
        </p>
        <div className="row text-center">
          <div className="col-lg-4">
            <div className="d-flex align-items-center justify-content-center">
              <img
                src={elerheto1}
                onClick={() => popUp(elerheto1)}
                alt=""
                onError={(e) => { e.target.src = 'images/alter.png'; }}
                id="elerheto1"
                className="colpics"
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex align-items-center justify-content-center">
              <img
                src={elerheto2}
                onClick={() => popUp(elerheto2)}
                alt=""
                onError={(e) => { e.target.src = 'images/alter.png'; }}
                id="elerheto2"
                className="colpics"
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex align-items-center justify-content-center">
              <img
                src={elerheto3}
                onClick={() => popUp(elerheto3)}
                alt=""
                onError={(e) => { e.target.src = 'images/alter.png'; }}
                id="elerheto3"
                className="colpics"
              />
            </div>
          </div>
        </div>
        <br />
        <div className="gradient-border" id="box"></div>
      </div>

      <div className="gradient-border" id="box"></div>

      <div className="container" id="galer">
        <h1 id='galeria' style={{ fontWeight: "bold", marginTop: 48, marginBottom: 48 }}>Galéria</h1>
        <div className="row text-center">
          {images.map((src, index) => (
            <div key={index} className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: '20px' }}>
              <div className="d-flex align-items-center justify-content-center">
                <img
                  src={src}
                  onClick={() => popUp(src)}
                  alt={`Artwork ${index}`}
                  className="colpics"
                />
              </div>
            </div>
          ))}
        </div>
        <div id='media'></div>
      </div>
      
      
      <div className="gradient-border" id="box"></div>
      
      <div className="container text-center">
      <h1 id='media' style={{ fontWeight: "bold", marginTop: 48, marginBottom: 48 }}>Média megjelenések</h1>
      <Newspaper />
      </div>
      

      <div className="gradient-border" id="box"></div>

      <div className="container text-center">
        <h1 id='rolam' style={{ fontWeight: "bold", marginTop: 48, marginBottom: 48 }}>Rólam</h1>
        <div className="aboutme-background" style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <div
            className="row uzi"
            data-aos="flip-up"
            data-aos-duration="1000"
            data-aos-delay="200"
            data-aos-once="true"
          >
            <div className="col-lg-4 kerdes">
              - Mi vezetett el téged a művészethez?
            </div>
            <div className="col-lg-8">
              Alapvetően, mint minden gyermek, szerettem firkálni és elmerülni ebben a tevékenységben. Ez a hobbim
              azóta is megmaradt. Képzőművészeti tapasztalataimat egy rajzkörben kezdtem gyűjteni. Ezen keresztül
              fejlődött az elvonatkoztatási készségem, megváltozott a gondolkodásmódom, és jó környezetben voltam. A
              festő szakra nem jutottam be, viszont a táblás restaurátorira igen, ebben is dolgoztam. Munkám szűk
              határokat adott, a kevés önálló gondolat, a szín- és vonalhűség nem sok teret hagyott alkotói mivoltam
              kiteljesedésében. De izgalmas volt, élveztem. Egy délelőtt pont leadtam egy képet és egy társmúzeum
              munkatársa megjelent egy fizikailag sérült kortás művel, örömömre én kaptam. Felrobbantam a képtől,
              szerettem vele foglalkozni, szerettem az érzést. Beállt a fordulat.
            </div>
          </div>

          <div
            className="row uzi"
            data-aos="flip-up"
            data-aos-duration="1000"
            data-aos-delay="300"
            data-aos-once="true"
          >
            <div className="col-lg-4 kerdes">
              - Ha nem művész lennél, mivel foglalkoznál?
            </div>
            <div className="col-lg-8">
              Szeretem kifejezni magam különböző alkotásokban és létrehozni valamit. Élvezem az acél megmunkálását,
              szobrok készítését. Érdekelnek a gépek, és örömmel ismerkedem velük, javítom őket. Szerencsésnek
              mondhatom magam, hisz ebben dolgozom. Ahhoz a végzett képzős 97%-hoz, tartozom, aki nem tud megélni
              pusztán a képzőművészetből. Cserébe az ipar ad egy létbiztonságot.
            </div>
          </div>

          <div
            className="row uzi"
            data-aos="flip-up"
            data-aos-duration="1000"
            data-aos-delay="400"
            data-aos-once="true"
          >
            <div className="col-lg-4 kerdes">
              - Mit jelent neked a kortárs művészet?
            </div>
            <div className="col-lg-8">
              Úgy gondolom, ez kétféleképpen értelmezhető. Egyrészt konzervatív beállítottságú vagyok, értékelem a
              hagyományos festészet technikai ismeretét, az ecsetvonások szépségét és a perspektívikus ábrázolás
              szabályait. Ugyanakkor a kortárs művészet egy lehetőséget kínál arra, hogy kilépjek a megszokott
              szabályok közül. Ebben az irányzatban szinte bármit meg lehet tenni, nincsenek alapvető irányelvek vagy
              izmusok (stílusjegyek). Őszinte érzésből fakad, van, hogy spekulatív, néha nem szól semmiről. A kortárs
              művészetben, ami számomra érték azt nagyon kevés művésznél találom meg. De mégis van, amit szeretek
              benne, talán maga az alkotás szabad folyamata, ezt fejtem vissza. Ebben valószínűleg fejlődnöm kell.
            </div>
          </div>

          <div
            className="row uzi"
            data-aos="flip-up"
            data-aos-duration="1000"
            data-aos-delay="500"
            data-aos-once="true"
          >
            <div className="col-lg-4 kerdes">
              - Hogy jellemeznéd a saját műveid?
            </div>
            <div className="col-lg-8">
              Ettől a kérdéstől tartottam. Nem tartozik sehová, kortársnak izmusos és fordítva. A műfajok jól
              összekeverője vagyok. Olykor pontos, máskor laza, hevenyészett. Érzéseket, hangulatot, információt,
              aktuálisan tényeket, és még nem is tudom, mit szeretnék átadni az alkotás megtekintőjének. Szeretnék
              valamit közvetíteni és az változó. Világunk folyamatos változásban van, nincs biztos pont, csak az örök
              változás. Előfordul geometrikus elvonatkoztatás, az optikai és a realisztikus festészet átformálásra
              koncentráló. Munkáimban bizonyos korszakokra jellemző stílusjegyeket, illetve különböző médiumú és
              fajtájú elemeket olvasztok egybe, melyből egy szubjektív narratív értelmezési lehetőséget fejlesztek.
              Persze ezek változni fognak, mert nem vagyok állandó.
            </div>
          </div>

          <div
            className="row uzi"
            data-aos="flip-up"
            data-aos-duration="1000"
            data-aos-delay="600"
            data-aos-once="true"
          >
            <div className="col-lg-4 kerdes">
              - Hogy néz ki nálad az alkotás folyamata?
            </div>
            <div className="col-lg-8">
              Mindig az adott nyomokat-elemeket kapom fel, ami megérint vagy korábban hatással volt rám, azt
              előhúzom ötletként, megfűszerezem a pillanat érzésével, becsomagolom a visszacsatolás burkába,
              áttranszformálom a magam nyelvére és hagyom alakulni a vászon felületén. Gyors skicceket vetek
              papírra, ez lesz az alkotás váza és ez is okozza az alkotói rendetlenségnek tűnő környezetem.
              Többnyire olajfestéket használok, vászonra dolgozom. Az alkotás folyamatát nem szakítom meg a rend
              parancsainak.
            </div>
          </div>

          <div
            className="row uzi"
            data-aos="flip-up"
            data-aos-duration="1000"
            data-aos-delay="700"
            data-aos-once="true"
          >
            <div className="col-lg-4 kerdes">
              - Jellemzően mennyi ideig dolgozol egy alkotáson?
            </div>
            <div className="col-lg-8">
              Ez teljesen változó. A kép részletessége és összetettsége meghatározza az időtartamát, de általában
              több napot vesz igénybe a teljes folyamat. Ha réteges színalkotást használok, akár hónapokig is
              eltarthat, mivel minden rétegnek meg kell száradnia a következő lazúrréteg felvitele előtt.
              Előfordul, hogy az alkotás folyamán módosításokat végzek, ami természetesen időigényes, de ez a
              művészi érték szempontjából hatásos lehet. Nem feltétlenül foglalkozom a rá fordított idővel festés
              közben, inkább előveszek egy másik témát és azon is dolgozom. Néha azért irigylem a víz alapú
              anyagokkal dolgozókat, persze ott is vannak határok.
            </div>
          </div>
        </div>
      </div>

      <div className="contact-background">
      <div className="gradient-border" id="box" style={{ marginTop: 48 }}></div>
        <h1 id='elerhetoseg' style={{ fontWeight: "bold", marginTop: 48, marginBottom: 48 }}>Elérhetőség</h1>
        <Contact />
      </div>
      
      <div>
        <img src="images/hullam.png" alt="" className="footerhullam" />
      </div>

      <div className="footer-class">
        <div className="row">
          <div className="col-lg-4 mb-5 text-center">
            <img src="images/matart-logo.png" alt="" className="footerlogo" /> <br />
            Mátyás Tamás
          </div>
          <div className="col-lg-4 mb-5 text-center">
            <b>Magyarország</b><br />
            Szekszárd
          </div>
          <div className="col-lg-4 mb-5 text-center">
            2023 © MINDEN JOG FENNTARTVA <br />
            <a href="https://duodesignz.com/" target="_blank" rel="noopener noreferrer">
              <img src="images/logo.png" alt="" className="footerlogo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
